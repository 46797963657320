import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = {
  key: 0,
  class: "card-body"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInvoiceForm = _resolveComponent("BaseInvoiceForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, "Update Invoice " + _toDisplayString(_ctx.invoice.number), 1)
    ]),
    (_ctx.invoice?.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_BaseInvoiceForm, {
            "invoice-id": _ctx.invoice.id,
            "is-generic": _ctx.isGeneric(_ctx.invoice.type.code),
            config: _ctx.config,
            "invoice-data": {
                         receivedDate: _ctx.invoice.receivedDate,
                            claimId: _ctx.invoice.claim.id,
                            type: _ctx.invoice.type.code,
                            dos: _ctx.invoice.dos ? _ctx.invoice.dos : NaN,
                            providerId: _ctx.invoice?.provider?.id,
                            currency: _ctx.invoice.currency,
                            payToId: _ctx.invoice?.payTo?.id,
                            referringId: _ctx.invoice?.referring?.id,
                            doctorId :_ctx.invoice?.doctor?.id,
                            dos2: _ctx.invoice.dos2,
                            lines: _ctx.invoice.lines.length > 0 ? _ctx.invoice.lines : _ctx.buildLines(_ctx.invoice.type.code),
                            data: _ctx.invoice.data ? _ctx.invoice.data : _ctx.data
                       },
            onSavedInvoice: _ctx.onSavedInvoice
          }, null, 8, ["invoice-id", "is-generic", "config", "invoice-data", "onSavedInvoice"])
        ]))
      : _createCommentVNode("", true)
  ]))
}