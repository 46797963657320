import {IBaseLine} from "@/core/entity/IBaseLine";

export interface IRXLine extends IBaseLine {

}

export class RXLine implements IRXLine {
  amount: number = 0;
  cpt: string = '';
  desc: string = '';
  diagnosis: string = '';
  emg: string = '';
  fromDt: number = NaN;
  modifier: string = '';
  pos: string = '';
  repriced: number = 0;
  rev: string = '';
  toDt: number = NaN;
  type: string = 'RX';
  units: number = 0;
}