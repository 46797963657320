import {IBaseInvoice} from "@/core/entity/IBaseInvoice";

export interface IStatement extends IBaseInvoice {
  note: string;
}

export class ModelStatement implements IStatement {
  diag1 = '';
  diag2 = '';
  diag3 = '';
  diag4: string = '';
  federalTaxNum: string = '';
  note: string = '';
  patientAccNumber: string = '';
  proc1: string = '';
  proc2: string = '';
  proc3: string = '';
  proc4: string = '';
  type: string = 'STATEMENT';
}