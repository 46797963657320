
import {computed, defineComponent, onMounted, ref, watch} from "vue";
import {LoadEntity} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import {HCFA_LINES, InvoiceType, LOST_BAGS, TRIP_CANS, UB04_LINES} from "@/core/config/Constant";
import router from "@/router";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {onUpdated} from "@vue/runtime-core";
import {ModelInvoiceHCFA} from '@/core/entity/IInvoiceHCFA';
import {ModelInvoiceUB} from '@/core/entity/IInvoiceUB04';
import BaseInvoiceForm from "@/views/invoice/BaseInvoiceForm.vue";
import {HCFAFieldConfig} from "@/core/entity/HCFAFieldConfig";
import {UB04FieldConfig} from "@/core/entity/UB04FieldConfig";
import {TripCanFieldConfig} from "@/core/entity/TripCanFieldConfig";
import {ModelTripCanInvoice} from "@/core/entity/ITripCanInvoice";
import {PrescriptionFieldConfig} from "@/core/entity/PrescriptionFieldConfig";
import {ModelPrescriptionDrugs} from "@/core/entity/IPrescriptionDrugs";
import {LostBaggageFieldConfig} from "@/core/entity/LostBaggageFieldConfig";
import {ModelLostBaggageInvoice} from "@/core/entity/ILostBaggageInvoice";
import {GenericFieldConfig} from "@/core/entity/GenericFieldConfig";
import {ModelInvoiceGeneric} from "@/core/entity/IInvoiceGeneric";
import {DentalFieldConfig} from "@/core/entity/DentalFieldConfig";
import {ModelInvoiceDental} from "@/core/entity/IInvoiceDental";
import {StatementFieldConfig} from "@/core/entity/StatementFieldConfig";
import {GenericLine} from "@/core/entity/IGenericLine";
import {ModelDentalLine} from "@/core/entity/IDentalLine";
import {StatementLine} from "@/core/entity/IStatementLine";
import {ModelStatement} from "@/core/entity/IStatement";
import {RXFieldConfig} from "@/core/entity/RXFieldConfig";
import {ModelRX} from "@/core/entity/IRX";
import {RXLine} from "@/core/entity/IRXLine";

export default defineComponent({
  name: "Update",
  components: {BaseInvoiceForm},
  setup() {
    const invoice = computed(() => store.state.InvoiceModule.invoice)
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Update Invoice', [
        {link: true, router: '/invoices/' + invoice?.value?.id, text: invoice?.value?.number},
        {link: false, router: '', text: 'Update Invoice'}
      ])
    })

    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Update Invoice', [
        {link: true, router: '/invoices/' + invoice?.value?.id, text: invoice?.value?.number},
        {link: false, router: '', text: 'Update Invoice'}
      ])
    })

    const onSavedInvoice = (i: any) => {
      router.push({
        name: 'invoiceOverview',
        params: {id: i.id}
      })
    }

    const config = ref<any>();
    const data = ref({});
    const buildConfig = (type: string) => {
      switch (type) {
        case InvoiceType.STATEMENT:
          config.value = StatementFieldConfig;
          data.value = new ModelStatement()
          break
        case InvoiceType.DENTAL:
          config.value = DentalFieldConfig;
          data.value = new ModelInvoiceDental()
          break
        case InvoiceType.HCFA:
          config.value = HCFAFieldConfig;
          data.value = new ModelInvoiceHCFA();
          break
        case InvoiceType.UB04:
          config.value = UB04FieldConfig;
          data.value = new ModelInvoiceUB();
          break
        case InvoiceType.TRIP_CAN:
          config.value = TripCanFieldConfig
          data.value = new ModelTripCanInvoice();
          break;
        case InvoiceType.PRESCRIPT:
          config.value = PrescriptionFieldConfig;
          data.value = new ModelPrescriptionDrugs()
          break;
        case InvoiceType.RX:
          config.value = RXFieldConfig;
          data.value = new ModelRX();
          break
        case InvoiceType.LOST_BAG:
          config.value = LostBaggageFieldConfig;
          data.value = new ModelLostBaggageInvoice()
          break
        case 'IVD':
        case 'IADD':
        case 'IT':
        case 'IMD':
        case 'IV':
        case 'IAA':
        case 'LA':
        case 'IO':
        case 'COB':

          config.value = GenericFieldConfig;
          data.value = new ModelInvoiceGeneric()
          break
      }
    }

    if (invoice.value.type) {
      buildConfig(invoice.value.type.code)
    }
    watch(invoice, (cb) => {
      buildConfig(cb.type.code)
    })

    return {
      invoice,
      ...LoadEntity(Actions.LOAD_INVOICE),
      HCFA_LINES,
      UB04_LINES,
      onSavedInvoice,
      ModelInvoiceHCFA,
      ModelInvoiceUB,
      InvoiceType,
      HCFAFieldConfig,
      UB04FieldConfig,
      config,
      data,
    }
  },
  methods: {
    isGeneric(type) {
      switch (type) {
        case 'IVD':
        case 'IADD':
        case 'IT':
        case 'IMD':
        case 'IV':
        case 'IAA':
        case 'LA':
        case 'IO':
        case 'COB':
          return true;
        default:
          return false
      }
    },
    buildLines(type) {
      switch (type) {
        case InvoiceType.STATEMENT:
          return new StatementLine();
        case InvoiceType.DENTAL:
          return new ModelDentalLine();
        case InvoiceType.UB04:
          return UB04_LINES;
        case InvoiceType.HCFA:
          return HCFA_LINES;
        case InvoiceType.PRESCRIPT:
          return [];
        case InvoiceType.RX:
          return new RXLine();
        case InvoiceType.TRIP_CAN:
          return TRIP_CANS;
        case InvoiceType.LOST_BAG:
          return LOST_BAGS;
        case 'IVD':
        case 'IADD':
        case 'IT':
        case 'IMD':
        case 'IV':
        case 'IAA':
        case 'LA':
        case 'IO':
        case 'COB':
          return new GenericLine()
        default:
          return []
      }
    },

  }
})
